import { create } from "zustand";

interface RouteInfo {
	path: string;
}

interface RouteTracker {
	history: RouteInfo[];
	reset: () => void;
	push: (path: RouteInfo) => void;
	pop: () => void;
}
/**
 * Web only. Not because it breaks on native, but because it's not needed on native.
 */
const useRouteTracker = create<RouteTracker>((set) => ({
	history: [],
	reset: () => set({ history: [] }),
	push: (path) =>
		set((state) => {
			state.history.push(path);
			return {
				history: state.history,
			};
		}),
	pop: () =>
		set((state) => {
			state.history.pop();
			return {
				history: state.history,
			};
		}),
}));
export default useRouteTracker;
