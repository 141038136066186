import { useRouter as useSolitoRouter } from "solito/navigation";
import _useRouter from "./hooks/_useRouter";

const useRouter = () => {
	const res = useSolitoRouter();

	return {
		...res,
		..._useRouter(),
	};
};
export default useRouter;
