import { PrefetchKind } from "next/dist/client/components/router-reducer/router-reducer-types";
import { useRouter } from "next/navigation";
import { useCallback, useRef } from "react";
import log from "src/helpers/log";
import { getCurTime_MS } from "src/shared/helpers/timeHelpers";
import useRouteTracker from "src/stores/useRouteTracker";
import { _UseRouter } from "../../types";

const joinParams = (
	url: string,
	{
		params,
		forwardParams,
	}: {
		params?: Record<string, string | null>;
		forwardParams?: boolean;
	},
) => {
	const paramsObj: Record<string, string> = Object.fromEntries(
		forwardParams ? new URLSearchParams(window.location.search.slice(1)).entries() : [],
	);
	if (params) {
		for (const [key, value] of Object.entries(params)) {
			if (value == null) {
				delete paramsObj[key];
			} else {
				paramsObj[key] = value;
			}
		}
	}

	if (Object.keys(paramsObj).length === 0) {
		return url;
	}
	const newUrl = url.includes("?")
		? `${url}&`
		: `${url}?` + new URLSearchParams(paramsObj).toString();
	return newUrl;
};

const _useRouter: _UseRouter = () => {
	const nextRouter = useRouter();

	const back = useCallback(() => {
		nextRouter.back();
	}, [nextRouter]);

	const push = useCallback<ReturnType<_UseRouter>["push"]>(
		(url, { ...options } = {}) => {
			nextRouter.push(joinParams(url, options), options);
		},
		[nextRouter],
	);

	const replace = useCallback<ReturnType<_UseRouter>["replace"]>(
		(url, { forwardParams, ...options } = {}) => {
			nextRouter.replace(joinParams(url, { forwardParams }), options);
		},
		[nextRouter],
	);

	const pushState = useCallback<ReturnType<_UseRouter>["pushState"]>((url, options = {}) => {
		window.history.pushState({}, "", joinParams(url, options));
	}, []);

	// There's a maximum rate of 100 per 30 seconds
	// We're going to divide that by 3 and round it down to have a smaller replace window
	// This might not be the most efficient, but if you're at the point where you have to optimize this, you're probably making too many calls
	const recentCalls = useRef<number[]>([]);
	const replaceState = useCallback<ReturnType<_UseRouter>["replaceState"]>(
		(url, options = {}) => {
			const cur_MS = getCurTime_MS();
			recentCalls.current = recentCalls.current.filter((time) => time > cur_MS - 10_000);
			recentCalls.current.push(cur_MS);
			if (recentCalls.current.length > 30) {
				log.error(
					{
						path: window.location.pathname,
					},
					"replaceState called too quickly",
				);
				return;
			}

			window.history.replaceState({}, "", joinParams(url, options));
		},
		[],
	);

	const prefetch = useCallback<ReturnType<_UseRouter>["prefetch"]>(
		(url, options = { kind: PrefetchKind.AUTO }) => {
			nextRouter.prefetch(url, options);
		},
		[nextRouter],
	);

	const reload = useCallback(() => {
		window.location.reload();
	}, []);

	const backTo = useCallback<ReturnType<_UseRouter>["backTo"]>(
		(url) => {
			const history = useRouteTracker.getState().history;
			const prevRoute = history.length > 1 ? history[history.length - 2] : undefined;
			if (prevRoute && url.startsWith(prevRoute.path)) {
				nextRouter.back();
			} else {
				nextRouter.replace(url);
			}
		},
		[nextRouter],
	);

	return {
		reset: back,
		popToTop: back,
		push,
		replace,
		pushState,
		replaceState,
		prefetch,
		reload,
		backTo,
	};
};
export default _useRouter;
